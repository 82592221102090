import { Box } from '@chakra-ui/react';
import BaseBillOfRights from '@components/ra/common/landingPages/BaseBillOfRights';
import Highlight from '@components/ra/interactive/Highlight';
import Body1 from '@components/ra/typography/Body1';
import moneyBag from '@public/ra/icons/primary/desktop/money-orange.svg';
import pencil from '@public/ra/icons/primary/desktop/pencil.svg';
import rx from '@public/ra/icons/primary/desktop/rx.svg';
import time from '@public/ra/icons/primary/desktop/time.svg';

const rightsData = [
  {
    header: "You deserve a broker who isn't influenced by commission.",
    iconSrc: moneyBag,
    iconAlt: 'Savings Icon',
    renderDescription: () => (
      <Body1>
        You should be on the right Medicare plan for your needs, regardless of how much it ends up paying your broker.
      </Body1>
    ),
  },
  {
    header: 'You deserve proof that all your doctors and drugs are covered.',
    iconSrc: rx,
    iconAlt: 'Prescriptions Icon',
    highlights: [
      {
        query: 'PDP [+]',
        tooltip: 'Prescription Drug Plan',
      },
      {
        query: 'MAPD [+]',
        tooltip: 'Medicare Advantage Plan',
      },
    ],
    renderDescription: () => (
      <Box display="inline" sx={{ ' *': { display: 'inline' } }}>
        <Highlight
          text="Whether you’re signing up for a PDP [+] or an MAPD [+], your advisor should check to see if it’s all covered (unfortunately, many companies make empty promises about this)."
          queries={[
            {
              key: 'PDP [+]',
              description: 'Prescription Drug Plan',
            },
            {
              key: 'MAPD [+]',
              description: 'Medicare Advantage Prescription Drug Plan',
            },
          ]}
          renderText={(text) => <Body1>{text}</Body1>}
        />
      </Box>
    ),
  },
  {
    header: 'You deserve to take your time choosing the right plan.',
    iconSrc: time,
    iconAlt: 'Time Icon',
    renderDescription: () => (
      <Body1>
        We don’t mean to be dramatic, but selecting your Medicare plan is a life-changing decision that determines your
        healthcare post-65.
      </Body1>
    ),
  },
  {
    header: 'You deserve to understand the pros AND cons of your plan.',
    iconSrc: pencil,
    iconAlt: 'Pencil Icon',
    renderDescription: () => (
      <Body1>Trust us, there are always both. For example, where there are savings, there are often trade offs.</Body1>
    ),
  },
];

const BillOfRights = () => <BaseBillOfRights rightsData={rightsData} />;

export default BillOfRights;
