import { Button, Flex, FormControl, FormErrorMessage, Heading, Input, Stack, Text } from '@chakra-ui/react';
import { sendToken } from '@components/login/requests';
import DefaultLayout from '@components/ra/common/layout/DefaultLayout';
import { Routes } from '@constants/routes';
import { antlerStyle, textInputHeight } from '@constants/styles';
import { useAuthenticatedContextProvider } from '@contexts/AuthenticatedContextProvider';
import validateEmail from '@utils/validateEmail';
import { Field, Form, Formik } from 'formik';
import router from 'next/router';

const BedrockIndex = () => {
  const { localUser, setLocalUser } = useAuthenticatedContextProvider();

  const handleSubmit = async ({ email }, { resetForm }) => {
    resetForm();
    setLocalUser({ ...localUser, email });
    await sendToken(email);
    router.push(Routes.checkEmail);
  };

  return (
    <DefaultLayout>
      <Stack
        px={[10, 10, 10, 100]}
        pt={['80px', '120px', '120px', 160]}
        minH={antlerStyle.bedrockHeroHeight}
        bg="white"
        spacing={6}
        alignItems="center"
      >
        <Heading>Login</Heading>
        <Text fontSize={18} textAlign="center">
          We'll send a secure login link to your email.
        </Text>
        <Flex>
          <Formik initialValues={{ email: '' }} onSubmit={handleSubmit}>
            {({ isSubmitting }) => (
              <Form>
                <Stack alignItems="center" spacing={0}>
                  <Field name="email" type="email" validate={validateEmail}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={form.errors.email && form.touched.email && field.value.length > 4}
                        w="300px"
                        h={textInputHeight}
                      >
                        <Input
                          id="email-input"
                          name="email"
                          type="email"
                          placeholder="Email Address"
                          autoComplete="email"
                          {...field}
                        />
                        <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Button whiteSpace="nowrap" type="submit" isLoading={isSubmitting} colorScheme="primary">
                    Send Link
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
        </Flex>
      </Stack>
    </DefaultLayout>
  );
};

export default BedrockIndex;
