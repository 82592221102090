import { useAuthenticatedContextProvider } from '@contexts/AuthenticatedContextProvider';
import BedrockIndex from './bedrock';
import FsmIndex from './fsm';

const Index = () => {
  const { isBedrock } = useAuthenticatedContextProvider();

  if (isBedrock) {
    return <BedrockIndex />;
  }

  return <FsmIndex />;
};

export default Index;
