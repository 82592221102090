import Index from '@components/ra/pages/index';
import { GetServerSideProps } from 'next';

export const getServerSideProps: GetServerSideProps = async (d) => {
  return {
    props: {
      clientHost: d.req.headers.host,
    },
  };
};

export default Index;
