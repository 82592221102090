import { AspectRatio, Box } from '@chakra-ui/react';
import { antlerStyle } from '@constants/styles';
import cb from '@public/enhanced/cb.png';
import laurie from '@public/enhanced/laurie.png';
import scott from '@public/enhanced/scott.png';
import theresa from '@public/enhanced/theresa.png';
import mindy from '@public/headshots/mindy.jpeg';
import Image from 'next/image';
import QuoteSlider from '../common/QuoteSlider';

const agentData = [
  {
    name: 'Laurie Bortolotti',
    quote:
      "I treat every person like they're my own parents and educate them on all options available so they can make an informed decision that gives them peace of mind.",
    image: laurie,
  },
  {
    name: 'Christopher Bryant',
    quote:
      'I love helping people get through all the confusion and love knowing they are part of the process in selecting their coverage that fits their individual needs.',
    image: cb,
  },
  {
    name: 'Mindy Wisdahl',
    quote:
      "My philosophy is to treat every client like family by listening and sharing. Even though I've sold thousands of policies, I know this process is new for them so we take our time and make sure we get it right.",
    image: mindy,
  },
  {
    name: 'Scott Cole',
    quote:
      "At Fair Square, we treat all clients like family, and assist them to meet their needs, not ours. We don't just enroll people in any plan; we enroll people in the right plan.",
    image: scott,
  },
  {
    name: 'Theresa Hyder',
    quote:
      'One size does not fit all! I love tailoring solutions to guarantee that my clients are well-protected for whatever health situation might come up.',
    image: theresa,
  },
];

const Advisors = () => (
  <Box px={antlerStyle.px} bg="tan">
    <Box w="100%" maxW="1400px" mx="auto">
      <QuoteSlider
        agentVariant
        eyebrow="Hear from our team of advisors"
        quotes={agentData}
        quoteHeight={['335px', '375px', '228px', '330px']}
        renderSelectedImage={({ imageSrc, alt }) => (
          <AspectRatio
            flex={1}
            mx="auto"
            minW={[300, 300, 350, 530]}
            w={[300, 300, 350, 530]}
            h={[300, 300, 350, 530]}
            justifyContent="center"
            position="relative"
          >
            <Image width={530} height={530} src={imageSrc} alt={alt} />
          </AspectRatio>
        )}
      />
    </Box>
  </Box>
);

export default Advisors;
