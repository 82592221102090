import BaseHero from '@components/ra/common/landingPages/BaseHero';

const Hero = () => {
  return (
    <BaseHero
      header="Your Medicare advisor for life"
      subheader="We rigorously sort through hundreds of plans for the one that fits you best, and make sure you get the best deal — and comprehensive coverage — year after year."
    />
  );
};

export default Hero;
