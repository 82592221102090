import { useQuery } from '@apollo/client';
import BaseReviews from '@components/ra/common/landingPages/BaseReviews';
import GET_REVIEWS from '@gql/Reviews';

const Reviews = () => {
  const { loading, data } = useQuery(GET_REVIEWS, {
    fetchPolicy: 'no-cache',
  });

  return <BaseReviews data={data} loading={loading} />;
};

export default Reviews;
