import BaseWhatToExpect from '@components/ra/common/landingPages/BaseWhatToExpect';
import agentCluster from '@public/ra/clusters/agent-cluster.png';
import coverage from '@public/ra/clusters/coverage-cluster.svg';
import enrolled from '@public/ra/clusters/enrolled-cluster.svg';
import reshop from '@public/ra/clusters/reshop-cluster.svg';
import { FsmColors } from 'chakra-ui/fsmTheme';

const stepsData = [
  {
    header: 'We get to know you in a free consultation call.',
    description:
      'We explain the ins and outs of Medicare, like Parts A and B, and how to choose between Plan G and  Medicare Advantage. Next, we’ll discuss your drugs, doctors, travel habits, and personal preferences, then narrow your options and explain next steps.',
    image: agentCluster,
    alt: 'Savings Icon',
    bg: FsmColors.blue,
  },
  {
    header: 'In our second call, we finalize your plan choice.',
    description:
      'Two weeks later, once you have your Medicare number (don’t worry—you’ll know exactly how to get this!), we’ll choose the right Medicare Advantage or Medigap plan for you. We’ll make sure your drugs and doctors are covered, and that you’re aware of trade-offs. Then we’ll enroll you!',
    image: coverage,
    alt: 'Prescriptions Icon',
    bg: FsmColors.tan,
  },
  {
    header: "We personally confirm you're enrolled.",
    description:
      'Unfortunately, many Medicare brokerages don’t do this, which can lead to time-consuming and sometimes costly sign-up errors. We contact your Medicare plan to make sure it’s official and email you your confirmation and enrollment details.',
    image: enrolled,
    alt: 'Time Icon',
    bg: FsmColors.navy,
  },
  {
    header: 'We reshop your coverage every year to ensure you get the best deal.',
    description:
      'Every October, we proactively analyze your plan, along with your doctors, drugs, and lifestyle, comparing it to other plans on the market to make sure you’re getting the best coverage and value. If you’re not, we’ll help you switch.',
    image: reshop,
    alt: 'Pencil Icon',
    bg: FsmColors.teal,
  },
];

const WhatToExpect = ({ header = null }) => <BaseWhatToExpect stepsData={stepsData} header={header} />;

export default WhatToExpect;
