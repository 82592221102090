import BaseClients from '@components/ra/common/landingPages/BaseClients';
import Highlight from '@components/ra/interactive/Highlight';
import Body1 from '@components/ra/typography/Body1';
import Header2 from '@components/ra/typography/Header2';

const Clients = () => {
  const renderHeader = () => (
    <Highlight
      text="Not your typical brokerage [+]"
      queries={[
        {
          key: 'brokerage [+]',
          description:
            'A brokerage is a company that represents multiple insurance companies and helps you evaluate and choose a plan.',
        },
      ]}
      renderText={(text) => <Header2 lineHeight="180%">{text}</Header2>}
    />
  );

  const renderDescription = () => (
    <Highlight
      text="Traditional Medicare brokers operate by getting you on the plan that gives them the highest commission [+] from insurance companies. Here, we’re obsessed with matching our clients to the right plan. We’ll recommend a plan even if it pays us nothing."
      queries={[
        {
          key: 'commission [+]',
          description:
            'Insurance companies traditionally pay brokerages a commission for signing people up on their plans. At Fair Square, our recommendations are not influenced by that commission.',
        },
      ]}
      renderText={(text) => <Body1>{text}</Body1>}
    />
  );

  return <BaseClients renderHeader={renderHeader} renderDescription={renderDescription} />;
};

export default Clients;
