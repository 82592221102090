import DefaultLayout from '@components/ra/common/layout/DefaultLayout';
import Advisors from './Advisors';
import BillOfRights from './BillOfRights';
import CallUs from './CallUs';
import Clients from './Clients';
import FAQS from './FAQS';
import Hero from './Hero';
import News from './News';
import Reviews from './Reviews';
import WhatToExpect from './WhatToExpect';

const FsmIndex = () => (
  <DefaultLayout>
    <Hero />
    <Reviews />
    <Clients />
    <BillOfRights />
    <WhatToExpect />
    <Advisors />
    <News />
    <FAQS />
    <CallUs />
  </DefaultLayout>
);

export default FsmIndex;
